import React from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import SocialLink from "../ScoialLink/SocialLink";
import "./Footer.scss";

const Footer = () => {
	return (
		<>
			<footer>
				<div className="container">
					<div className="footer-sec top">
						<div className="footer-link">
							<ul>
								<li>
									<Link
										to="/leadership"
										className="footer-link"
									>
										OUR LEADERSHIP
									</Link>
								</li>
								{/* <li>|</li> */}
								{/* <li>
									<Link
										to="/our-customer"
										className="footer-link"
									>
										OUR CUSTOMER
									</Link>
								</li> */}
								<li>|</li>

								<li>
									<Link to="/csr" className="footer-link">
										CSR
									</Link>
								</li>
								<li>|</li>

								<li>
									<Link to="/career" className="footer-link">
										CAREER
									</Link>
								</li>
								<li>|</li>
								<li>
									<Link
										to="/contact-us"
										className="footer-link"
									>
										BUSINESS CONNECT
									</Link>
								</li>
								<li>|</li>
								<li>
									<Link to="/blog" className="footer-link">
										BLOG
									</Link>
								</li>
								<li>|</li>
								<li><a
									href="tel:911244338383"
									className="footer-link"
								>Other Inquiries : +91-124-4338383</a></li>
							</ul>
						</div>
						<SocialLink />
					</div>
					<div className="footer-sec mid">
						<div className="footer-link">
							<ul>
								<li>
									<a
										href="tel:9958058594"
										className="footer-link"
									>
										{/* <BsFillTelephoneFill /> */}
										Business Inquiry : +91-9958058594
									</a>
								</li>

								<li>
									<a
										href="mailto:sales@radicalminds.in"
										className="footer-link"
									>
										{/* <MdEmail />  */}
										Email : sales@radicalminds.in
									</a>
								</li>
								<li>|</li>
								<li>
									<a
										href="tel:919599290073"
										className="footer-link"
									>
										{/* <BsFillTelephoneFill />  */}
										HR Management : +91-9599290073
									</a>
								</li>

								<li>
									<a
										href="mailto:hiring@radicalminds.in"
										className="footer-link"
									>
										{/* <MdEmail />  */}
										Email : hiring@radicalminds.in
									</a>
								</li>
								<li>|</li>
								<li>
									<a
										href="mailto: info@radicalminds.in"
										className="footer-link"
									>
										{/* <MdEmail />  */}
										Information Desk :  info@radicalminds.in
									</a>
								</li>

							</ul>
						</div>
					</div>
					<hr />
					<div className="footer-sec pt-3">
						<div className="footer-link">
							{/* <span>
								<Link to="">Privacy Policy</Link>
							</span>{" "}
							|{" "}
							<span>
								<Link to="">Terms & Conditions</Link>
							</span> */}
						</div>
						<div className="copyright">
							<p className="text-lg-end text-center">
								Copyright © {new Date().getFullYear()} Radical Minds &nbsp;&nbsp; <br />
								<a href="/terms-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> |  {" "}
								<a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>   | {" "}
								<a href="/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a> | {" "}
								Site best viewed in IExplorer 10+ | Chrome 65+ | Firefox 70+ | Edge 95+
							</p>
						</div>

					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
