import React, { useEffect } from 'react';
import { ThankyouImage } from '../../assets'; 
import './Thankyou.css'; 
import PageLayout from '../../Components/PageLayout';
import { Container } from 'react-bootstrap';

const Thankyou = () => {
    useEffect(() => {
        // Load the Google Tag script dynamically
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16626637016";
        document.head.appendChild(script);

        // Add Google Analytics configuration script
        const script2 = document.createElement("script");
        script2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16626637016');
        `;
        document.head.appendChild(script2);

        // Cleanup function to remove the script when navigating away
        return () => {
            document.head.removeChild(script);
            document.head.removeChild(script2);
        };
    }, []);

    const handleHomeClick = () => {
        window.location.href = '/';
    };

    return (
        <PageLayout
            title={
                "Multilingual BPO | Outsourced Virtual Call Center Solutions | Offshore Customer Support Services India"
            }
        >
            <Container>
                <div className="thankyou-page">
                    <img src={ThankyouImage} alt="Thank you" className="img-fluid" />
                    <div className="thankyou-content">
                        <h1>Thank You for Your Inquiry!</h1>
                        <p>We appreciate your interest in our services, products, and solutions. Our team will review your request and get back to you shortly.</p>
                    </div>
                    <button className="home-button" onClick={handleHomeClick}>
                        Home
                    </button>
                </div>
            </Container>
        </PageLayout>
    );
}

export default Thankyou;
